import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { prismicGetByType } from "../../lib/PrismicHelper";
import * as prismic from '@prismicio/client';
import LoadBackgroundImage from "../../shared/components/LoadBackgroundImage";
import { RichText } from "prismic-reactjs";
import Conditional from "../../shared/Conditional";
import styles from "./DiscountCodeBanner.module.scss";
import useQueryMap from "../../hooks/useQueryMap";


// For logged in users, we need the customer account holder information to
// determine whether to show the discount code banner or not, instead of
// checking the URL for the presence of a referral code.
export default function DiscountCodeBanner({customerAccountHolder = null}) {
  const { i18n } = useTranslation();
  const [code, setCode] = useState(null);
  const [banner, setBanner] = useState(null);
  const query = useQueryMap();

  const lang = i18n.language;

  useEffect(() => {
    if (customerAccountHolder?.referralOnboardingPromoType === "special") {
      // Logged in user -> use this info
      setCode(customerAccountHolder.referralOnboardingPromoId);
    } else {
      // Anonymous user -> check the URL for a discount code
      const ref = query.get("ref", "");
      setCode(ref);
    }
  }, [customerAccountHolder]);

  useEffect(() => {
    if (!code) return undefined;

    // Fetch discount data from Prismic and display banner if available
    prismicGetByType({locale: lang}, 'discount_banner', {
      predicates: [
        prismic.predicate.at('my.discount_banner.discount_code', String(code).toUpperCase()),
      ],
    }).then((discountPage) => {
      setBanner(<Banner {...discountPage.results[0]} />);
    }).catch((error) => {
      console.error("Error fetching discount data: ", error);
    });
  }, [code, lang]);

  return banner;
}

function Banner({data}) {
  if (!data) return null;

  const {
    background_color = "var(--secondary-gold-100)",
    border_color = "var(--secondary-gold-600)",
    corner_radius = "4px",
    discount_code, // Must be defined as we fetched it
    external_padding = "16px",
    logo = null,
    logo_margin = null,
    text, // Must be defined
    text_margin = null,
  } = data;

  const containerStyle = {
    border: "1px solid",
    backgroundColor: background_color,
    borderColor: border_color,
    borderRadius: corner_radius,
    padding: external_padding,
  }
  
  const classes = [
    styles.module,
    "discount-banner",
  ].join(" ");

  return (
    <div className={classes} style={containerStyle}>
      <Conditional show={logo != null}>
        <div className={styles.logoParent} style={{margin: logo_margin, height: `${logo.dimensions?.height}px`}}>
          <LoadBackgroundImage prismicImage={logo} style={{backgroundPositionX: "left"}} />
        </div>
      </Conditional>
      <div className={styles.textParent} style={{margin: text_margin}}>
        <RichText render={text} />
      </div>
    </div>
  )
}
