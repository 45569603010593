import React, {Component} from "react";
import {Field, reduxForm, formValueSelector} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import DropDownField from "../../shared/components/forms/DropDownField";
import FormError from "../../shared/components/forms/FormError";
import {List} from "immutable";
import {COUNTRY_CODE_KEY, COUNTRY_LABEL_KEY, getStatesOrProvinces, preferredCountries} from '../../lib/CountriesAndStates';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Conditional from "../../shared/Conditional";


function toUpperCase(value) {
  return value && value.toUpperCase();
}

function getSubmitLabel({t, submitting, submitLabel, isCreation}) {
  if (submitting) return t("standby");

  if (submitLabel) return submitLabel;

  return isCreation
    ? t("billing.add_address")
    : t("billing.edit_address");
}

class BillingAddressForm extends Component {
  componentDidUpdate(prevProps, prevState) {
    const countryWasChanged = prevProps.addressCountryCode !== this.props.addressCountryCode
    const isInitialRender = prevProps.addressCountryCode === undefined
    if (countryWasChanged && !isInitialRender) {
      this.props.change("addressStateProvince", "");
    }

    if (this.props.status !== prevProps.status && this.props.status === "submit") {
      this.props.submit();
    }
  }

  render() {
    let {errors, handleSubmit, submitting} = this.props;
    
    let submitLabel = getSubmitLabel(this.props);

    let stateProvinceInput = null;
    if (this.props.statesOrProvinces.size > 0) {
      stateProvinceInput = <Field
        label={this.props.t("forms:state_province")}
        name="addressStateProvince"
        mode=""
        component={DropDownField}
        options={this.props.statesOrProvinces}
        autoComplete="address-level1"
        valueKey="code"  
        titleKey="name"
        serverError={errors.addressStateProvince}
        />
    }

    const buttonsSize = this.props.buttonsSize || Button.SIZES.SMALL;

    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <Conditional show={this.props.showIntroText}>
            <p>{this.props.t("billing.enter_address")}</p>
          </Conditional>
          <Field
            label={this.props.t("forms:name_or_company")}
            name="name"
            placeholder={this.props.t("forms:full_name_placeholder")}
            mode=""
            component={TextInputField}
            serverError={errors.name}
          />
          <Field
            label={this.props.t("forms:country")}
            name="addressCountryCode"
            preferredValues={preferredCountries}
            placeholder={this.props.t("forms:country_placeholder")}
            component={DropDownField}
            options={this.props.countries}
            withSpritePath="/assets/flags/sprites-vertical.png"
            autoComplete="country"
            mode=""
            valueKey={COUNTRY_CODE_KEY}
            titleKey={COUNTRY_LABEL_KEY}
            serverError={errors.addressCountryCode}
          />
          <Field
            label={this.props.t("forms:address_line_1")}
            name="addressAddressLine1"
            placeholder={this.props.t("forms:address_line_1_placeholder")}
            mode=""
            autoComplete="address-line1"
            component={TextInputField}
            serverError={errors.addressAddressLine1}
          />
          <Field
            label={this.props.t("forms:address_line_2")}
            name="addressAddressLine2"
            placeholder={this.props.t("forms:address_line_2_placeholder")}
            mode=""
            autoComplete="address-line2"
            component={TextInputField}
            serverError={errors.addressAddressLine2}
          />
          <Field
            label={this.props.t("forms:city")}
            name="addressCity"
            placeholder={this.props.t("forms:city_placeholder")}
            mode=""
            autoComplete="address-level2"
            component={TextInputField}
            serverError={errors.addressCity}
          />
          <Field
            label={this.props.t("forms:post_code")}
            name="addressPostcode"
            placeholder={this.props.t("forms:post_code_placeholder")}
            mode=""
            autoComplete="postal-code"
            normalize={toUpperCase}
            component={TextInputField}
            serverError={errors.addressPostcode}
          />

          {stateProvinceInput}

        </div>
        <Conditional show={this.props.showSubmitButton}>
          <div className="buttons">
            <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
              label={submitLabel} active={!submitting} size={buttonsSize} />
            <Button label={this.props.t("cancel")} active={!submitting}
              onClick={this.props.onCancel}
              theme={Button.THEMES.BLUE_OUTLINE}
              addCancelToTopRight={true}
              visible={this.props.showCancel} size={buttonsSize} />
          </div>
        </Conditional>
          
        <FormError error={errors._error} />
      </form>
    )
  }
}

BillingAddressForm.defaultProps = {
  statesOrProvinces: List([]),
  showCancel: true,
  showIntroText: true,
  showSubmitButton: true,
}

const ReduxForm = reduxForm({
  form: "BillingAddressForm",
  // Allow update of fields through changes to initialValues
  enableReinitialize: true,
  // Prevent such updates from impacting fiels that were edited by the user
  keepDirtyOnReinitialize: true,
})(BillingAddressForm);

const selector = formValueSelector("BillingAddressForm");
function mapStateToProps(state, ownProps) {
  const addressCountryCode = selector(state, "addressCountryCode")
  return {
    addressCountryCode: addressCountryCode,
    statesOrProvinces: getStatesOrProvinces(addressCountryCode) || List([])
  }
}

export default withTranslation("common")(connect(mapStateToProps)(ReduxForm));
