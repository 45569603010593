import React, { useEffect, useRef, useState } from "react";
import {Field, getFormValues, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import styles from "./PromoCodeForm.module.scss";
import Conditional from "../../shared/Conditional";
import textInputStyles from "../../shared/components/forms/TextInputField.module.scss";
import useResize from "../../hooks/useResize";


const INLINE_MODE_CUTOFF = 380;


function upcase(val) {
  return String(val).toUpperCase();
}

function PromoCodeForm(props) {
  const { t } = useTranslation("checkout");
  const {
    noLabel = false,
    errors, handleSubmit, submit, submitting,
    small = false,
  } = props;
  
  const baseRef = useRef();
  const { width } = useResize(baseRef);
  const [inlineMode, setInlineMode] = useState(false);
  
  useEffect(() => {
    if (width) {
      setInlineMode(width > INLINE_MODE_CUTOFF);
    }
  }, [width]);
  
  let submitLabel = submitting ? t("common:standby") : t("summary.promo_code.apply");

  const formValues = useSelector(state => getFormValues("PromoCodeForm")(state));
  const codeFormHasValue = !!formValues?.get("promoCode");

  const baseClasses = [
    styles.module,
    inlineMode ? styles.inline : "",
    small ? styles.small : "",
  ].join(" ")

  return (
    <form className={baseClasses} ref={baseRef} onSubmit={handleSubmit}>
      <div className={styles.inner}>
        <Conditional show={!noLabel}>
          <label className={textInputStyles.label} htmlFor={"promoCode"}>{t("summary.promo_code.label")}</label>
        </Conditional>
        <div className={styles.inputAndButton}>
          <Field
            name="promoCode"
            placeholder={t("summary.promo_code.placeholder")}
            component={TextInputField}
            normalize={upcase}
            mode=""
            table={false}
            serverError={errors.promoCode}
          />
          <Button label={submitLabel} className={styles.submitButton}
            onClick={submit} active={!submitting && codeFormHasValue}
            size={small ? Button.SIZES.SMALL : Button.SIZES.MEDIUM} />
        </div>
      </div>
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "PromoCodeForm"
})(PromoCodeForm);

export default ReduxForm;