export function trackingUrl(courierId, trackingNr, trackingUrl) {
  switch(courierId) {
    case "ups":
    case "ware2go":
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNr}`;
    case "parcelforce":
      return `http://www.parcelforce.com/track-trace?trackNumber=${trackingNr}`;
    case "gls":
      return `https://gls-group.eu/EU/en/parcel-tracking?match=${trackingNr}`;
    case "swisspost":
      return `https://www.post.ch/swisspost-tracking?formattedParcelCodes=${trackingNr}`;
    case "hallstein":
      return `https://tracking.hallsteinwater.com/tracking/${trackingNr}`;
    case "byrd":
      return trackingUrl;
    default:
      return "#"

  }
}