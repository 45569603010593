import React from "react";
import styles from "./PromoCode.module.scss";

export default function PromoCode({active, code, noBottomMargin}) {
  if (active !== true) return null;

  const classes = [
    styles.module,
    noBottomMargin ? styles.noBottomMargin : ""
  ].join(" ")

  return (
    <div className={classes}>
      <span className={styles.prefix}>Applied Code: </span><span className={styles.code}>{code}</span>
    </div>
  )
}

PromoCode.defaultProps = {
  active: false,
  code: ""
}