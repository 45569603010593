import React, {useState} from "react";
import { createErrors, createValidationErrors } from "../../../lib/ErrorFormatter";
import { useTranslation } from "react-i18next";
import { Map } from "immutable";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Form from "../../../shop/forms/PromoCodeForm";
import { useDispatch } from "react-redux";
import { reset } from "redux-form/immutable";


function PromoCodeFormContainer(props) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_payment.promo_code"});

  const {
    anonymousCheckoutInfo = Map(),
    small = false,
    noLabel = true,
  } = props;
  
  // If the orderId is available, it means we are far enough in the onboarding
  // process.
  const isReady = !!anonymousCheckoutInfo.get("orderId");

  // Set billing to be the same as shipping address mutation:
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();


  function handleSubmit(values) {
    let updatedValues = values.set("anonymousCartId", anonymousCheckoutInfo.get("cartId"));

    // Don't do anything when we don't have a code.
    // This way we don't show error message needlessly.
    if (values.get("promoCode", "") === "") {
      setErrors({
        promoCode: t("error_empty"),
      });
      
      return;
    }

    return props.anonymousApplyPromoCodeToCheckout(updatedValues.toJS())
      .then((response) => {
        if (response.data.anonymousApplyPromoCodeToCheckout.errors.length <= 0) {
          dispatch(reset("PromoCodeForm"));
          setErrors([]);
        } else {
          const errors = createValidationErrors(response.data.anonymousApplyPromoCodeToCheckout.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });      
  }
  
  if (!isReady) {
    // First step of the checkout: cannot input the promo code yet
    return null;
  }

  return (
    <Form
      noLabel={noLabel}
      errors={errors}
      onSubmit={handleSubmit}
      small={small}
    />
  )
}

const APPLY_CHECKOUT_DISCOUNT = gql`
  mutation anonymousApplyPromoCodeToCheckout($data: CustomerPromoCodeInput!) {
    anonymousApplyPromoCodeToCheckout(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(APPLY_CHECKOUT_DISCOUNT, {
    props: ({ mutate }) => ({
      anonymousApplyPromoCodeToCheckout: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withQueries(PromoCodeFormContainer);