import React, {Component} from "react";
import CartItem from "./CartItem";
import Table from "../../shared/Table";
import { cx } from "../../shared/lib/ClassSet";
import {List, fromJS} from "immutable";
import { withTranslation } from 'react-i18next';
import "./ProductTable.css";

function getItemKeyForCartItem(item) {
  let suffix = item.get("subscription") === true ? "-sub" : "";
  return `${item.get("productSku")}${suffix}`;
}

class ProductTable extends Component {

  render() {
    const subscriptionItems = this.props.products.filter((item) => item.get("subscription"))
    const normalItems = this.props.products.filter((item) => item.get("subscription") === false)
    const classes = cx({
      "ProductTable": true,
      "u-with-subscription": subscriptionItems.size > 0,
      "u-with-subtotal": this.props.showSubtotal
    })

    return (
      <div className={classes}>
        {this.renderSubscriptionItems(subscriptionItems, this.props.subscriptionFrequencyWeeks)}
        {this.renderNormalItems(normalItems)}
      </div>
    )
  }

  buildTableHeader(isSubscription) {
    let tableHeader = List();
    const productLabel = isSubscription ? this.props.t("summary.table.subscriptions") : this.props.t("summary.table.single_orders");
  
    tableHeader = tableHeader.push(fromJS({id: "id", label: productLabel}));
    if (this.props.showButtons) {
      tableHeader = tableHeader.push(fromJS({id: "button", label: ""}));
    }

    if (!this.props.tightMode) {
      tableHeader = tableHeader.push(fromJS({id: "quantity", label: this.props.t("summary.table.quantity")}));
      tableHeader = tableHeader.push(fromJS({id: "price", label: this.props.t("summary.table.unit_price")}));
    }

    // Only in tight mode do we add an indication that there is a discount
    // as otherwise it's obvious with the above price per unit.
    const totalLabel = this.props.tightMode && this.props.hasDiscount
      ? this.props.t("summary.table.total_with_discount")
      : this.props.t("summary.table.total");
    tableHeader = tableHeader.push(fromJS({id: "total", label: totalLabel}));

    if (this.props.showSubtotal) {
      tableHeader = tableHeader.push(fromJS({id: "subtotal", label: this.props.t("summary.table.subtotal")}));
    }
  
    return tableHeader;
  }
  
  renderSubscriptionItems(items, frequencyWeeks) {
    if (items.size === 0) return null;

    const tableHeader = this.buildTableHeader(true);
    let rendererParams = {
      showSubtotal: this.props.showSubtotal,
      showButton: this.props.showButtons,
      tightMode: this.props.tightMode,
    }

    return (
      <React.Fragment>
        <div className="header subscriptionHeader">
          <div className="title">{this.props.t("products:subscriptions")}</div>
          <div className="frequency">{this.props.t("accounts:subscription.delivery_every", { count: frequencyWeeks })}</div>
        </div>
        <Table 
          extraClassName="CartList SubscriptionCartList"
          data={items} 
          renderer={CartItem} 
          rendererParams={rendererParams}
          mode="table"
          tableHeader={tableHeader}
          onItemClick={this.handleItemClick.bind(this)}
          selected=""
          getItemKey={getItemKeyForCartItem}
          />
      </React.Fragment>
    )
  }

  renderNormalItems(items) {
    if (items.size === 0) return null;

    const tableHeader = this.buildTableHeader(false);
    let rendererParams = {
      showSubtotal: this.props.showSubtotal,
      showButton: this.props.showButtons,
      tightMode: this.props.tightMode,
    }

    return (
      <React.Fragment>
        <div className="header">
          <div className="title">Single Orders</div>
        </div>          
        <Table 
          extraClassName="CartList OneTimeOrderCartList"
          data={items} 
          renderer={CartItem}
          rendererParams={rendererParams}
          mode="table"
          tableHeader={tableHeader}
          onItemClick={this.handleItemClick.bind(this)}
          selected=""
          getItemKey={getItemKeyForCartItem}
        />
      </React.Fragment>
    )
  }  

  handleItemClick(itemData) {
    if (this.props.onItemClick) {
      this.props.onItemClick(itemData);
    }
  }
}

ProductTable.defaultProps = {
  subscriptionFrequencyWeeks: 0,
  items: List([]),
  showSubtotal: true,
  showButtons: false,
  tightMode: false,
  hasDiscount: false,
}

export default withTranslation("checkout")(ProductTable);