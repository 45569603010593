import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import Conditional from "../../../shared/Conditional";
import Products from "../Products";
import "./OrderSummary.scss";
import useResizeListener from "../../../hooks/useResizeListener";
import { getProductDiscount } from "../../../lib/StatsSummary";


const ORDER_SUMMARY_TIGHT_CUTOFF = 400;


const OrderSummary = (props) => {
  const { t } = useTranslation("checkout")
  const { data, productTitles, displayTitle = true, children = null } = props;

  const baseRef = useRef();
  const { width } = useResizeListener(baseRef.current);
  const [tightMode, setTightMode] = useState(false);

  useEffect(() => {
    if (width) {
      setTightMode(width < ORDER_SUMMARY_TIGHT_CUTOFF);
    }
  }, [width]);

  if (!data.get("products")) {
    console.error("OrderSummary: Missing products data");
    return null;
  }

  const currency = data.get("currency") || "";
  const showDiscount = (data.get("promoCodeApplied") === true);
  let discount = `${currency} -${data.get("discount")}`;
  let taxes = `${currency} ${data.get("subtotalTaxes", "--")}`;
  let total = `${currency} ${data.get("total", "--")}`;
  if (data.get("isPreparing")) {
    discount = <LoadingLine key="discount-loading" />
    taxes = <LoadingLine key="taxes-loading" />
    total = <LoadingLine key="total-loading" />
  }

  // We update `products` with titles from `productTitels` if available.
  let products = data.get("products").map((product) => {
    return product.set("productTitle", productTitles.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
  });

  return (
    <div className="OrderSummary" ref={baseRef}>
      <Conditional show={displayTitle}>
        <div className="title">{t("summary.title")}</div>
      </Conditional>
      <Products
        tightMode={tightMode}
        products={products}
        subscriptionFrequencyWeeks={data.get("subscriptionFrequencyWeeks")}
        hasDiscount={hasDiscount(products)}
      />
      <div className="content">
        <table>
          <tbody>
            <Conditional show={showDiscount}>
              <tr>
                <td>{t("summary.code_discount")}</td>
                <td>{discount}</td>
              </tr>
            </Conditional>
            <Conditional show={children != null}>
              <tr>
                <td colSpan={2}>{children}</td>
              </tr>
              <tr className="large-separator">
                <td colSpan="2"><hr/></td>
              </tr>
            </Conditional>
            <tr>
              <td>{t("summary.taxes")}</td>
              <td>{taxes}</td>
            </tr>
            <tr className="large-separator">
              <td colSpan="2"><hr/></td>
            </tr>
            <tr className="order-total">
              <td>{t("summary.total")}</td>
              <td>{total}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function hasDiscount(products) {
  return products && products.some((product) => product && !!getProductDiscount(product))
}

export default OrderSummary;
