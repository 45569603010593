import React, {Component} from "react";
import {Field} from "redux-form/immutable";
import {reduxForm} from "../../lib/ReduxFormWrapper";
import TextInputField from "../../shared/components/forms/TextInputField";
import DropDownField from "../../shared/components/forms/DropDownField";
import Checkbox from "../../shared/components/forms/Checkbox";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import Navigation from "../../lib/Navigation";
import { withTranslation, Trans } from 'react-i18next';


class WaitingListForm extends Component {
  // The "countries" prop is set as a result of a query, so we listen to the 
  // props updates to act on it.
  componentWillReceiveProps(nextProps) {
    if (this.props.countries.length === 0 && nextProps.countries) {
      // Init the field with the assumed country we got from the server,
      // but only if the value is valid for the shop list of countries.
      const assumedCountryCode = this.props.assumedCountryCode;

      if (nextProps.countries.map(e => e.get("code")).includes(assumedCountryCode)) {
        this.props.initialize({ countryCode: assumedCountryCode });
      }
    }
  }

  render() {
    let {errors, handleSubmit, submitting, darkBackground, hideCountrySelect} = this.props;
    let submitLabel = submitting ? this.props.t("common:standby") : this.props.t("waiting_list.join");

    let countrySelectStyles = { 
      display: "contents"
    }
    if (hideCountrySelect) {
      countrySelectStyles = {
        display: "none"
      }
    }

    const privacyPolicy = (
      <span className="sg-font-bold">
        <Trans i18nKey="auth:agree_terms"
          components={{
            a: <Button className="sg-font-bold"
              theme={darkBackground ? Button.THEMES.WHITE : Button.THEMES.BLACK}
              link={true} onClick={Navigation.gotoTermsAndConditions} />
          }}
        />
      </span>
    );

    return (
      <form onSubmit={handleSubmit} autoComplete="on">
        <div className="fields">
          <Field
            label={this.props.t("forms:full_name")}
            name="name"
            mode=""
            placeholder={this.props.t("forms:full_name_placeholder")}
            component={TextInputField}
            serverError={this.props.errors.name}
            noTable={true}
            darkBackground={darkBackground}
            />
          <Field
            label={this.props.t("forms:email")}
            name="email"
            mode=""
            placeholder={this.props.t("forms:email_placeholder")}
            component={TextInputField}
            serverError={this.props.errors.email}
            noTable={true}
            darkBackground={darkBackground}
            />
          <div style={countrySelectStyles}>
            <Field
              label={this.props.t("forms:country")}
              name="countryCode"
              mode=""
              placeholder={this.props.t("forms:country_placeholder")}
              component={DropDownField}
              options={this.props.countries}
              withSpritePath="/assets/flags/sprites-vertical.png"
              titleKey="name"
              valueKey="code"
              serverError={this.props.errors.countryCode}
              darkBackground={darkBackground}
              />
          </div>
          {/* <Field
            label="Private or Business?"
            name="kind"
            mode=""
            component={DropDownField}
            options={this.props.kinds}
            showBlank={false}
            titleKey="name"
            valueKey="code"
            serverError={this.props.errors.kind}
            /> */}
          <div className="sg-bold u-bottom-margin-large sg-text-tiny">
            <Field
              label={privacyPolicy}
              name="policyAcceptance"
              className="u-bottom-margin"
              component={Checkbox}
              serverError={this.props.errors.policyAcceptance}
              darkBackground={darkBackground}
              />
            <Field
              label={<span className="sg-font-bold">{this.props.t("receive_marketing")}</span>}
              name="marketingAcceptance"
              component={Checkbox}
              serverError={this.props.errors.marketingAcceptance}
              darkBackground={darkBackground}
              />
          </div>
        </div>
        <div className="buttons">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
            label={submitLabel} active={!submitting}
            size={Button.SIZES.MEDIUM}
            className="expand sg-font-bold "/>
        </div>

        <FormError error={errors._error}
          darkBackground={this.props.darkBackground} />
      </form>
    )    
  }
}

WaitingListForm.defaultProps = {
  darkBackground: true,
  hideCountrySelect: false,
}

const ReduxForm = reduxForm({
  form: "WaitingListForm",
})(WaitingListForm);

export default withTranslation("auth")(ReduxForm);