import React from "react";
import styles from "./JurisdictionFlag.module.scss";

// Real:    56 x 40
// smaller: 21 x 15
// small:   35 x 25
// big:     42 x 30

const SIZES = {
  SMALLER: styles.smaller,
  SMALL: styles.small,
  BIG: styles.big,
}

const JurisdictionFlag = ({jurisdictionId, size = SIZES.SMALL}) => {
  const classes = [styles.module, size, jurisdictionId];

  return (
    <div className={classes.join(" ")} />
  );
}

JurisdictionFlag.SIZES = SIZES;

export default JurisdictionFlag;