import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";
import MessageBus from "../lib/MessageBus";
import GraphQLErrors from "../shared/GraphQLErrors";
import {fromJS} from "immutable";
import Panel from "./shared/Panel";
import NotificationsPanel from "./views/NotificationsPanel";
import BillingPanel from "./views/BillingPanel";
import DeliveriesController from "./views/DeliveriesController";
import ChargesController from "./views/ChargesController";
import BillingIssues from "./views/BillingIssues";
import Velocity from "velocity-animate";
import {getMediaQuerySize} from "../reducers/SystemReducer";
import Divider from "./shared/Divider";
import Submenu from "../shared/components/Submenu";
import ShopLayout from "./ShopLayout";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import styles from "./AccountsPage.module.scss";
import AccountSubscriptions from "./views/AccountSubscriptions";
import { loadAccountPageCards } from "../lib/api/CmsApi";
import DiscountCodeBanner from "./views/DiscountCodeBanner";


function AccountsPage(props) {
  const {
    accountId,
    data,
  } = props;

  const refDeliveries = useRef();
  const refCharges = useRef();
  const refPage = useRef();
  const refPanels = useRef();

  const { i18n, t } = useTranslation("accounts");

  const [accountDecks, setAccountDecks] = useState();
  const [noSubscriptionsCard, setNoSubscriptionsCard] = useState();
  const [discountBanner, setDiscountBanner] = useState(null);

  useEffect(() => {
    fetchAccountDecks(i18n.language, setAccountDecks, setNoSubscriptionsCard);
  }, [i18n.language]);
  
  useEffect(() => {
    const unsubscribeSubscriptionsChanges = props.subscribeToSubscriptionsChanges(props, {});
    const unsubscribeBillingChanges = props.subscribeToBillingChanges(props, {});
    const unsubscribeBillingIssues = props.subscribeToBillingIssues(props, {});
    const unsubscribeCartChanges = props.subscribeToCartChanges(props, {});

    const profileSubscription = MessageBus.subscribe("auth", "profileUpdated", () => {
      console.debug("MessageBus profileUpdated");
      props.data.refetch();
    });

    return () => {
      unsubscribeSubscriptionsChanges();
      unsubscribeBillingChanges();
      unsubscribeBillingIssues();
      unsubscribeCartChanges();
      MessageBus.unsubscribe(profileSubscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  function handleViewDeliveries() {
    scrollToPosition(refDeliveries.current.getBoundingClientRect().y);
  }

  function handleViewCharges() {
    scrollToPosition(refCharges.current.getBoundingClientRect().y);
  }

  function scrollToPosition(top) {
    Velocity(refPage.current, "stop");
    return Velocity(refPage.current, {
      tween: [top, refPage.current.scrollTop]
    }, {
      duration: 500,
      progress: (elements, complete, remaining, current, tweenValue) => {
        refPage.current.scrollTop = tweenValue;
      }
    });
  }

  if (data.error) return <GraphQLErrors error={data.error} />

  const accountSubmenu = <AccountSubmenu t={t} parentRef={refPanels} />
    
  const issues = fromJS(data.customerBillingIssues);
  const subscriptions = fromJS(data.customerSubscriptions);
  const billingAccount = fromJS(data.customerBillingAccount);
  const defaultPaymentMethod = fromJS(data.customerDefaultPaymentMethod);

  if (discountBanner === null && !!data.customerAccountHolder?.referralOnboardingPromoId) {
    setDiscountBanner(<DiscountCodeBanner customerAccountHolder={data.customerAccountHolder} />)
  }
  
  const billingIssuesElem = issues && issues.size > 0 && (
    <React.Fragment>
      <BillingIssues
        accountId={accountId}
        issues={issues}
      />
    </React.Fragment>);

  const alertElem = billingIssuesElem && (
    <div className={styles.alerts}>
      {billingIssuesElem}
      <Divider spaceAround={Divider.SPACES.TINY} firstOrLast={true} />
    </div>);

  return (
    <ShopLayout className={styles.module} headerSubmenu={accountSubmenu}
      boundary={props.boundary} accountId={accountId}
      pageRef={refPage}
      discountBanner={discountBanner}>
      
      { alertElem }

      <div className={styles.panels + " " + styles.subscriptions} ref={refPanels}>
        <div id="subscription" className={styles.anchor}>
          <AccountSubscriptions accountId={accountId}
            subscriptions={subscriptions}
            noSubscriptionsCard={noSubscriptionsCard}
            decks={accountDecks}
            handleViewDeliveries={handleViewDeliveries}
            handleViewCharges={handleViewCharges}
          />
        </div>

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <div id="deliveries" className={styles.anchor} ref={refDeliveries}>
          <DeliveriesController
            narrowSize={props.isMediumSize || props.isNarrowSize}
            accountId={accountId}
            />
        </div>

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <div id="billingDetails" className={styles.anchor}>
          { billingAccount &&
            <BillingPanel
              accountId={accountId}
              billingAddress={billingAccount.get("address")}
              billingAddressStatus={billingAccount.get("addressStatus")}
              taxId={billingAccount.get("taxId")}
              validTaxId={billingAccount.get("validTaxId")}
              taxExempt={billingAccount.get("taxExempt")}
              status={billingAccount.get("status")} />
          }
        </div>

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <div id="paymentMethod" className={styles.anchor}>
          { billingAccount &&
            <BillingPanel
              accountId={accountId}
              paymentMethod={billingAccount.get("paymentMethod")}
              paymentTermsDays={billingAccount.get("paymentTermsDays")}
              defaultPaymentMethod={defaultPaymentMethod} />
          }
        </div>

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <div id="paymentHistory" className={styles.anchor} ref={refCharges}>
          <ChargesController
            narrowSize={props.isMediumSize || props.isNarrowSize}
            accountId={accountId}
            />
        </div>

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <div id="notifications" className={styles.anchor}>
          <Panel title={t("notifications.name")}
            subtitle={t("notifications.subtitle")}
            subpanels={<NotificationsPanel accountId={accountId} />}
            loading={data.loading} />
        </div>

        {/* Partners Perks */}
      </div>
    </ShopLayout>
  );
}


const AccountSubmenu = (props) => {
  const { hash } = useLocation();

  const items = [
    { hash: "#subscription", label: props.t("subscription.name") },
    { hash: "#deliveries", label: props.t("deliveries.name") },
    { hash: "#billingDetails", label: props.t("billing.name") },
    { hash: "#paymentMethod", label: props.t("payment_method.name") },
    { hash: "#paymentHistory", label: props.t("payment_history.name") },
  ];

  return (
    <Submenu items={items} currentHash={hash}
      noSeparators spaceEvenly parentRef={props.parentRef} />
  );
}

async function fetchAccountDecks(locale, setDecks, setNoSubscriptionsCard) {
  const query = await loadAccountPageCards(locale);
  // there can be only one doc
  if (query.response.edges.length !== 1) return null; // errored

  const decks = query.response.edges[0].node;

  const prioritySort = (e1 = 0, e2 = 0) => e1.priority < e2.priority;

  const sortedDecks = {
    essentialCards: fromJS(decks.essential_cards
      .sort(prioritySort)
      .map((e) => ({ ...e.card, essential: true}))
    ),
    optionalCards: fromJS(decks.optional_cards
      .sort(prioritySort)
      .map((e) => ({ ...e.card, optional: true}))
    ),
  }
  
  setNoSubscriptionsCard(fromJS(decks.no_subscriptions_banner));
  setDecks(sortedDecks);
}

const SUBSCRIPTIONS = gql`
  query customerSubscriptions($accountId:ID!) {
    customerBillingIssues(accountId:$accountId) {
      salesOrderId
      accountId
      errorCode
      declineCode
      description
      willRetry
      amount
      currency
      updatedAt
    }
    customerSubscriptions(accountId:$accountId) {
      subscriptionId
      name
      accountId
      frequencyWeeks
      status
      wasImported
      currentSeqId
      hasEstimatedDeliveryDate
      nextEstimatedDeliveryDate
      canModifyNextDelivery
      products {
        productSku
        productTitle
        quantity
        total
        currency
      }
      shippingAddress {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
      shippingAddressSurcharges {
        productSku
        surcharge {
          currency
          value
        }
      }
    }
    customerDefaultPaymentMethod(accountId:$accountId) {
      paymentMethodId
      type
      cardBrand
      cardExpYear
      cardExpMonth
      cardLast4
      default
      addedAt
    }    
    customerBillingAccount(accountId:$accountId) {
      address {
        name
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince 
        addressCountryName
        addressCountryCode              
      }
      addressStatus
      paymentMethod
      paymentTermsDays
      taxId
      validTaxId
      status
    }
    customerAccountHolder(accountId:$accountId) {
      referralOnboardingPromoType
      referralOnboardingPromoId
    }
  }
`;

const ON_UPDATED_ACCOUNT_SUBSCRIPTIONS = SubscriptionQueryWrapper("$accountId: ID!", `
  updatedAccountSubscriptions(accountId: $accountId) {
    accountId
  }`
);

const ON_UPDATED_BILLING = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBilling(accountId: $accountId) {
    accountId
  }`
);

const ON_UPDATED_BILLING_ISSUES = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBillingIssues(accountId: $accountId) {
    accountId
  }`
);

const ON_UPDATED_CART = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedCart(accountId: $accountId) {
    accountId
  }`
);


const mapDispatchToProps = dispatch => ({
  subscribeToSubscriptionsChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNT_SUBSCRIPTIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  subscribeToBillingChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_BILLING,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  subscribeToBillingIssues: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_BILLING_ISSUES,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },  
  subscribeToCartChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_CART,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  }
})

const withQuery = compose(
  graphql(SUBSCRIPTIONS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId,
        deliveriesFilter: {
          limit: 7
        }
      }
    })
  })
);

const mapStateToProps = (state) => {
  const isNarrow = getMediaQuerySize(state.get("system")) === "mq-sm";

  return {
    isMediumSize: !isNarrow && state.getIn(["system", "width"]) < 1100,
    isNarrowSize: isNarrow,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withQuery(AccountsPage));
