import { useDispatch } from "react-redux";
import { showNotification } from "../../../actions/NotificationActions";
import { generateUUID } from "../../../lib/UUID";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


export default function TotalUpdatesNotifier({total}) {
  const { t } = useTranslation("checkout");
  const dispatch = useDispatch();

  useEffect(() => {
    if (total && total != "--") {
      dispatch(showNotification(generateUUID(), t("notifications.total_changed")));
    }
  }, [total]);
  
  return null;
}