import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ShopFooter from "./shared/ShopFooter";
import ShopHeader from "./shared/ShopHeader";
import styles from "./ShopLayout.module.scss";
import Navigation from "../lib/Navigation";
import ShopHeaderMobile from "./shared/ShopHeaderMobile";
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import LoadingCircle from "../shared/components/forms/LoadingCircle";
import useScrollToHash from "../hooks/useScrollToHash";


const ShopLayout = (props) => {
  const {
    accountId,
    showHeader = true,
    showFooter = false, showFooterCheckout,
    noWhiteSpaceAround = false,
    desktopHeaderCutoff = 1420, // header menu needs a lot of width on desktop
    discountBanner = null, // if defined, will be shown at the top of the page below the header
  } = props;
  useScrollToHash();

  const showMobileHeader = props.windowWidth < desktopHeaderCutoff;

  const [hideBanner] = useState(false);
  const banner = null;

  const needPushDownMainContent = showHeader;
  const pushDownMainContentForBanner = !hideBanner && needPushDownMainContent && (
    <div className={styles.invisibleBanner}>
      { banner }
    </div>
  );

  // Customer name cookie gets updated whenever necessary
  const customerName = Cookies.get("cname");

  const shopHeaderOptions = {
    boundary: props.boundary,
    accountId: accountId,
    banner: hideBanner ? null : banner,
    handleSignOut: Navigation.signOut,
    customerName: customerName,
  };

  const shopHeader = showHeader && (
    <div className={styles.header}>
      <ShopHeader show={!showMobileHeader} submenu={props.headerSubmenu} {...shopHeaderOptions} />
      <ShopHeaderMobile show={showMobileHeader} {...shopHeaderOptions} />
    </div>
  );

  const shopFooter = showFooter && (
    <div className={styles.footer}>
      <ShopFooter accountId={accountId} showCheckout={showFooterCheckout} />
    </div>
  );

  const baseClasses = [
    styles.module,
    props.className,
    noWhiteSpaceAround && styles.noWhiteSpaceAround,
    showMobileHeader ? styles.layoutMobile : styles.layoutDesktop,
    !showMobileHeader && props.headerSubmenu && styles.withSubmenu,
    showHeader && styles.showHeader,
    showFooter && styles.showFooter,
  ];

  // The element we need to use for scrolling is different on mobile and
  // desktop, and the page ref could be passed as a prop too.
  const innerPageRef = useRef();
  let refToUseForScrolling = window;
  if (!showMobileHeader) {
    refToUseForScrolling = props.pageRef || innerPageRef;
  }

  return (
    <div className={baseClasses.join(" ")}>
      <ScrollToTop pageRef={refToUseForScrolling} />
      { shopHeader }

      <div className={styles.inner} ref={innerPageRef}>
        { pushDownMainContentForBanner }
        { !!discountBanner ? <div style={{marginTop: "-16px"}}>{discountBanner}</div> : null }
        { props.loading && <LoadingCircle center /> }
        { props.children }
      </div>

      { shopFooter }
    </div>
  );
}

// Make sure we scroll to top after every navigation, untless it's going back
// in history or there is a hash in the url.
const ScrollToTop = (props) => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP' && !location.hash) {
        const elem = props.pageRef.current || window;
        window.setTimeout(() => elem.scrollTo(0, 0), 1);
      }
    })
    return () => unlisten()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

const mapStateToProps = (state) => {
  return {
    windowWidth: state.getIn(["system", "width"]),
  }
}

export default connect(mapStateToProps)(ShopLayout);