import { Map, List } from "immutable";
import { stringToFloat } from "./Money";

export function generatePurchaseSummary(data, invoiceNr, shopOrigin="shop") {
  // Values are strings.
  return Map({
    currency: data.get("currency"),
    transaction_id: invoiceNr,
    value: stringToFloat(data.get("total", "0,00")),
    coupon: getPromoCode(data),
    items: genLineItemsForPurchase(data.get("products"), Map({
      shopId: data.get("shopId"),
      shopOrigin: shopOrigin,
      promoCode: getPromoCode(data)
    }))
  })
}

export function generateCheckoutSummary(data, shopOrigin="shop") {
  // Values are integers.
  return Map({
    currency: data.get("currency"),
    value: data.get("subtotal"),
    items: genLineItemsForCheckout(data.get("items"), Map({
      shopId: data.get("shopId"),
      shopOrigin: shopOrigin
    }))
  })
}

export function generateViewCartSummary(data, shopOrigin="shop") {
  return Map({
    currency: data.get("currency"),
    value: data.get("subtotal"),
    items: genLineItemsForCheckout(data.get("items"), Map({
      shopId: data.get("shopId"),
      shopOrigin: shopOrigin
    }))
  })
}

export function generateAddToCartSummary(sku, title, quantity, isSubscription, price, currency, shopId, shopOrigin="shop") {
  return Map({
    currency: currency,
    value: price * quantity,
    items: List([genLineItem(sku, title, price, quantity, isSubscription, shopId, shopOrigin)])
  })
}

// Has a different format than the others as this data comes from various sources.
// Pricing struct has `currency`, `price` and `subscriptionPrice`.
// If any of the price fields are undefined, that means that one-time or subscription is not available.
// If we have price for both types, we add 2 products instead of just 1.
//
// Examples:
// { currency: "EUR", price: 62, subscriptionPrice: 62 }
// { currency: "EUR", price: 42, subscriptionPrice: undefined }
// { currency: "EUR", price: undefined, subscriptionPrice: 100 }
export function generateViewItemSummary(sku, title, pricing, shopId, shopOrigin="shop") {
  let subscriptionItem, onetimeItem;
  let itemsList = List();
  if (pricing.get("subscriptionPrice", 0) > 0) {
    subscriptionItem = genLineItem(sku, title, pricing.get("subscriptionPrice"), 1, true, shopIdToPublicId(shopId), shopOrigin);
    itemsList = itemsList.push(subscriptionItem);
  }
  if (pricing.get("price", 0) > 0) {
    onetimeItem = genLineItem(sku, title, pricing.get("price"), 1, false, shopIdToPublicId(shopId), shopOrigin);
    itemsList = itemsList.push(onetimeItem);
  }

  return Map({
    currency: pricing.get("currency"),
    items: itemsList
  })
}

// Not sure if we can use the same item_id (SKU) more than once...
// Exported to ease testing.
export function genLineItemsForPurchase(products, attrs = Map()) {
  // Values are strings.
  return products.map((product) => 
    Map({
      item_id: product.get("productSku"),
      item_name: product.get("productTitle"),
      affiliation: attrs.get("shopOrigin"),
      item_category: purchaseCategory(product),
      item_category2: productCategory(product),
      coupon: getProductPromoCode(product, attrs.get("promoCode")),
      discount: getProductDiscount(product),
      location_id: shopIdToPublicId(attrs.get("shopId")),
      price: stringToFloat(product.get("unitPrice")),
      quantity: product.get("quantity")
    })
  );
}

// Not sure if we can use the same item_id (SKU) more than once...
// Exported to ease testing.
export function genLineItemsForCheckout(products, attrs = Map()) {
  // Values are integers.
  return products.map((product) => 
    Map({
      item_id: product.get("productSku"),
      item_name: product.get("productTitle"),
      affiliation: attrs.get("shopOrigin"),
      item_category: purchaseCategory(product),
      item_category2: productCategory(product),
      location_id: shopIdToPublicId(attrs.get("shopId")),
      price: product.get("unitPrice"),
      quantity: product.get("quantity")
    })
  );
}

// Used for building up some events like view_event
export function genLineItem(sku, title, price, quantity, subscription, shopId, shopOrigin="shop") {
  return Map({
    item_id: sku,
    item_name: title,
    affiliation: shopOrigin,
    item_category: getPurchaseCategory(subscription),
    item_category2: getProductCategory(sku),
    location_id: shopIdToPublicId(shopId),
    price: price,
    quantity: quantity
  });
}


// -- Helpers -- //

function getPromoCode(data) {
  if (data.get("promoCodeApplied", false) === true) {
    return data.get("promoCode", "");
  }
  return "";
}

function getProductPromoCode(product, promoCode) {
  if (product.get("unitDiscount", "0,00") !== "0,00") {
    return promoCode
  }
  return "";
}

export function getProductDiscount(product) {
  if (product.get("unitDiscount", "0,00") !== "0,00") {
    return stringToFloat(product.get("unitDiscount", "0,00"));
  }
  return 0.0;
}

function purchaseCategory(product) {
  return getPurchaseCategory(product.get("subscription"));
}

function getPurchaseCategory(subscription) {
  return subscription ? "subscription" : "onetime";
}

function productCategory(product) {
  return getProductCategory(product.get("productSku"));
}

function getProductCategory(sku) {
  if (sku.startsWith("WAT-")) {
    return "water";
  } else if (sku.startsWith("DPR-") || sku.startsWith("BDL-DPR-")) {
    return "dispenser";
  } else {
    return "other";
  }
}

function shopIdToPublicId(shopId) {
  switch(shopId) {
    case "alpine-water-usa-llc": return "US";
    case "alpine-water-gmbh": return "EU";
    case "alpine-water-schweiz-gmbh": return "CH";
    case "hallstein-water-canada-inc": return "CA";
    case "alpine-water-uk": return "UK";
    case "hallstein-water-sg": return "SG";

    default: return shopId;
  }
}