import React from "react";
import {Field} from "redux-form/immutable";
import {reduxForm} from "../../lib/ReduxFormWrapper";
import { PASSWORD_MIN_LENGTH } from "../../lib/Validators";
import TextInputField from "../../shared/components/forms/TextInputField";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { withTranslation } from 'react-i18next';


function RegisterPasswordForm(props) {
  const {errors, handleSubmit, submitting, darkBackground} = props;
  const submitLabel = submitting ? props.t("common:standby") : props.t("register_password.register"); 

  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      <div className="fields" style={{marginBottom: "24px"}}>
        <Field
          label={props.t("forms:password")}
          name="password"
          comment={props.t("forms:validation.password_comment")}
          mode=""
          placeholder="*******"
          minLength={PASSWORD_MIN_LENGTH}
          obscured
          component={TextInputField}
          serverError={props.errors.password}
          noTable={true}
          darkBackground={darkBackground}
        />
      </div>
      <div className="u-bottom-margin">
        <Button onClick={handleSubmit(values => props.onSubmit(values))}
          label={submitLabel} active={!submitting}
          theme={Button.THEMES.BLUE}
          size={Button.SIZES.MEDIUM}
          className="expand"/>
      </div>
      
      <FormError error={errors._error} className="u-bottom-margin"
        darkBackground={darkBackground} />
    </form>
  )    
}

RegisterPasswordForm.defaultProps = {
  darkBackground: true,
  showReferralCode: false
}

const ReduxForm = reduxForm({
  form: "RegisterPasswordForm",
  destroyOnUnmount: false,
})(RegisterPasswordForm);

export default withTranslation("auth")(ReduxForm);